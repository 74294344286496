<template>
    <div>
        <v-card outlined v-for="(filter, index) in filters" :key="filter.title"
                :class="{ 'mt-4': index > 0 }">
            <v-card-title class="pt-2 pb-0 text-body-1 d-flex justify-space-between align-self-end">
                {{ filter.title }} 
                <div class="justify-end">
                    <span class="clickable mr-2" @click="clearFilters()">Clear</span>
                    <v-icon class="clickable" @click="enableFilters()">mdi-close</v-icon>
                </div> 
            </v-card-title>
            <v-card-text>
                <v-checkbox
                    v-for="option in filter.options"
                    :key="option.label"
                    v-model="option.active"
                    :label="option.label"
                    class="mt-1 text-body-2"
                    hide-details
                    @change="sendFilterItemChange(option)">
                </v-checkbox>
            </v-card-text>
        </v-card>
    </div>
  </template>
  <script>
  
  export default {
    name: "OverviewFilter.vue",
    props: {
      filters: {
        type: Array,
        required: true,
        default: () => []
      },
      filterState: {
        type: Boolean,
        default: false,
        required: true,
      }
    },
    components: {
    },
    data() {
      return {
        showFilter: null,
      }
    },
    methods: {
      sendFilterItemChange(option) {
        this.$emit('update-filteritem', option)
      },
      enableFilters() {
        this.showFilter = !this.filterState;
        this.$emit('update:filterState', this.showFilter)

      },
      clearFilters() {
        this.$emit('clearfilter')
      },
    },
  }
  </script>