import { translateNationalitiy } from "@/shared/utils/translateUtils"

export function getGoogleMapsUrl(input) {
  if (!input.address) return null

  let inputAddressAsArray = [
      input.address.street?.trim(),
      input.address.number?.trim(),
      input.address.bus?.trim(),
      input.address.postalCode?.trim(),
      input.address.city?.trim(),
      translateNationalitiy(
          input.address.countryCode
      )?.replace(" ", "+"),
  ]

  const inputAddressAsQuery = inputAddressAsArray.filter((n) => n).join("+")

    if (inputAddressAsQuery) {
        return `${process.env.VUE_APP_GOOGLE_MAPS_API_URL}?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&q=${inputAddressAsQuery}`
    } else {
        return null
    }
}

export function getGoogleMapsUrlByCoordinates(latitude, longitude) {
  if (latitude && longitude) {
      return `${process.env.VUE_APP_GOOGLE_MAPS_API_URL}?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&q=(${latitude},${longitude})`
  } else {
      return null
  }
}