<template>
  <v-col cols="12" :md="staySmallAboveMedium ? '5' : '12'" class="d-flex">
    <iframe v-if="mapsURL" class="flex-grow-1 w-100" style="border: 0" loading="lazy" allowfullscreen
      :src="mapsURL">
    </iframe>
    <div v-else class="d-flex flex-column flex-grow-1 w-100">
      <v-icon class="align-self-center flex-grow-1" size="110px">mdi-map-marker</v-icon>
      <div class="align-self-center">{{ unavailableMessage }}</div>
    </div>
  </v-col>

</template>

<script>


export default {
  name: "GoogleMapsFrame",
  props: {
    mapsURL: {
      // type can be string OR null
      default: null,
      required: true,
    },
    unavailableMessage: {
      type: String,
      default: "Geen locatie beschikbaar",
      required: false,
    },
    staySmallAboveMedium: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>