<template>
  <v-dialog v-model="show" max-width="500px" :fullscreen="$vuetify.breakpoint.xsOnly"
            transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-toolbar dark dense>
        <v-card-title> Opgepast</v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-card-text>
          <h3 class="text-justify"> Je gaat {{ message }} verwijderen. Ben je zeker?</h3>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="button" color="primary" @click="close">
            Annuleer
          </v-btn>
          <v-btn type="button" color="primary" @click="submit">
            Bevestig
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DeleteItemDialog.vue",
  props: {
    message: {
      type: String,
      required: true
    },
    storeAction: {
      type: String,
      required: true
    },
    itemPayload: {
      required: true
    }
  },
  data() {
    return {
      show: true,
    }
  },
  methods: {
    close() {
      this.show = false
      this.$emit('close')
    },
    submit() {
      this.$store.dispatch(this.storeAction, this.itemPayload).then(()=>this.close())
    },
  }
}
</script>