<template>    
    <v-dialog v-bind:value="value" v-on:input="$emit('input', $event.target.value)" max-width="500px" 
        persistent>
      <v-card>
        <BasicToolbar :title="title" :withoutClose="true"/>
        <v-card-text >
          <v-card-text>
            <h3>{{ description }}</h3>
          </v-card-text>
          <v-card-actions class="pl-4">
            <v-btn
              type="button" color="secondary"
              :outlined="true"
              @click="abort"
            >
              {{ abortButtonText }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="button" color="secondary"
              @click="confirm"
            >
              {{ confirmButtonText }}
            </v-btn>
          </v-card-actions>
          </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import BasicToolbar from "@/components/basic/BasicToolbar";

export default {
    name: "ConfirmationDialog",
    components: {
        BasicToolbar
    },
    props: {
        value: {
            default: false,
            type: Boolean
        },
        title: String,
        description: String,
        confirmButtonText: {
            default: "Bevestig",
            type: String
        },
        onConfirm: {
          default: () => {},
          type: Function
        },
        abortButtonText: {
            default: "Annuleer",
            type: String
        },
        onAbort: {
          default: () => {},
          type: Function
        },
    },
    methods: {
        confirm() {
            this.onConfirm()
            this.$emit('input', false)
        },
        abort() {
            this.onAbort()
            this.$emit('input', false)
        }
    }
}
</script>