<template>
  <BasicInformationCard title="Projecten" :promises.sync="promises">
    <template v-slot:content>
      <overview :items="getProjects" :headers="headers" :filters="filters" add-btn-text="Nieuw project"
        :add-item-component="pushToCreateRoute" :succesFunction="fetchClient" :detail-page-function="detailPageFunction"
        :showCreateButton="getClientStatus">
        <template v-slot:item.status="{ item }">
          <v-chip :color="item.active ? 'green' : 'red'" label>
            {{ item.status }}
          </v-chip>
        </template>
      </overview>
    </template>
  </BasicInformationCard>
</template>

<script>

import RouteNames from "@/router/RouteNames";
import Overview from "@/components/shared/Overview";
import BasicInformationCard from "@/components/shared/BasicInformationCard.vue";

export default {
  name: "ClientDetailProjectListComponent",
  components: {
    BasicInformationCard,
    Overview,
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Naam",
          value: 'projectName'
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      filters: [
        {
          title: "Status",
          options: [
            {
              label: "Actief",
              predicate: (project) => project.active,
            },
            {
              label: "Inactief",
              predicate: (project) => !project.active,
            }
          ]
        }
      ],
      client: null,
      detailPageFunction: (project) => {
        return {
          name: RouteNames.PROJECT_DETAIL,
          params: {
            projectId: project.id,
            clientId: project.clientId
          }
        }
      },
      promises: [this.fetchClient()]
    }
  },
  methods: {
    fetchClient() {
      return this.$store.dispatch('clientsModule/fetchClient', this.clientId)
        .then((result) => this.client = result)
    },
    pushToCreateRoute() {
      this.$router.push({ name: RouteNames.PROJECT_CREATE, params: { clientId: this.clientId } })
    },
  },

  computed: {
    getClientStatus() {
      return this.$store.state.clientsModule.client?.active
    },
    getProjects() {
      // TODO should be a retrieved from a separate project state store
      if (!this.$store.state.clientsModule.client?.projects || !this.client) {
        return [];
      }
      return this.$store.state.clientsModule.client.projects.map(project => ({
        ...project,
        status: project.active ? 'actief' : 'inactief',
        clientId: this.client.id,
        clientName: this.client.clientName
      })
      )
    },
  }
}
</script>
