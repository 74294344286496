<template>
    <v-card flat outlined>
        <v-toolbar class="background--cultured-gray" flat>
            <v-toolbar-title class="font-weight-medium">
                {{ title }}
            </v-toolbar-title>
            <v-spacer/>
            <router-link v-if="editRouterLink" :to="editRouterLink" class="text-decoration-none clickable">
              <v-icon dense class="vertical-align-baseline">mdi-pencil</v-icon>
            </router-link>
        </v-toolbar>
        <v-divider/>
        <v-card-text>
            <PromiseContainer :promises.sync="computedPromises">
                <template v-slot:default>
                    <slot name="content"></slot>
                </template>
            </PromiseContainer>
        </v-card-text>
    </v-card>
</template>

<script>
import PromiseContainer from '@/components/shared/PromiseContainer.vue'

export default {
    name: "BasicInformationCard",
    props: {
        title: String,
        editRouterLink: null,
        promises: []
    },
    emits: ['update:promises'],
    components: {
        PromiseContainer
    },
    computed: {
        computedPromises: {
            get() {
                return this.promises
            },
            set(newValue) {
                this.$emit('update:promises', newValue)
            }
        }
    }
}
</script>