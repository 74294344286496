<template>
  <BasicInformationCard title="Contact informatie" :editRouterLink="{name: routeNames.CLIENT_DETAIL_EDIT_CONTACT_INFORMATION, params: {clientId: clientId}}"
                        :promises.sync="promises">
    <template v-slot:content>
      <v-container>
        <v-row v-for="(contact, index) in contactInformation.contactList" :key="index">
          <v-col cols="5">
            <BasicInformationItem description="Naam" :value="[contact.firstName, contact.lastName].filter(Boolean).join(' ')"/>
          </v-col>
          <v-col cols="5">
            <BasicInformationItem description="Telefoon" :value="contact.phone"/>
          </v-col>
          <v-col cols="5">
            <BasicInformationItem description="Email" :value="contact.email"/>
          </v-col>
          <v-col cols="5">
            <BasicInformationItem description="Functie" :value="contact.jobTitle"/>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasicInformationCard>
</template>

<script>

import BasicInformationCard from '../shared/BasicInformationCard.vue';
import BasicInformationItem from '../shared/BasicInformationItem.vue';
import routeNames from "@/router/RouteNames";

export default {
  name: "ClientContactInformationComponent",
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  components: {
    BasicInformationCard,
    BasicInformationItem
  },
  data() {
    return {
      routeNames,
      contactInformation: {
        contactList: [
          {
            firstName: "",
            lastName: "",
            phone: null,
            email: null,
            primaryContact: null,
            jobTitle: null
          }
        ]
      },
      promises: [
        this.$store.dispatch("clientsModule/fetchContactInformation", {clientId: this.clientId})
            .then(result => {
              if (result.contactList !== null){
                this.contactInformation = result
              }})
      ]
    }
  }
}
</script>