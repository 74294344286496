<template>
    <div>
        <p class="body-2 mb-0" :class="descriptionClass">{{ description }}</p>
        <slot :value="value" :set-value="setValue" name="value-display">
            <p class="subtitle-1 font-weight-medium black--text ">{{ value }}</p>
        </slot>
    </div>
</template>

<script>
    export default {
        name: "BasicInformationItem",
        model: {
            prop: "value",
            event: "update:value"
        },
        props: {
            description: String,
            value: null,
            descriptionClass: {}
        },
        methods: {
            setValue(newValue) {
                this.$emit("update:value", newValue)
            }
        }
    }
</script>