<template>
    <BasicInformationCard title="Klant informatie"
        :editRouterLink="{ name: routeNames.CLIENT_DETAIL_EDIT_CLIENT_INFORMATION, params: { clientId: clientId } }"
        :promises.sync="promises">
        <template v-slot:content>
            <v-container>
                <v-row>
                    <v-col cols="12" md="7">
                        <v-row>
                            <v-col cols="6">
                                <BasicInformationItem description="Start datum"
                                    :value="applyDateFormat(clientInformation.startDate)" />
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Eind datum"
                                    :value="applyDateFormat(clientInformation.endDate)" />
                            </v-col>
                          <v-col cols="6">
                            <BasicInformationItem description="btw nummer" :value="clientInformation.vat"/>
                          </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Adres" :value="clientInformation.address">
                                    <template v-slot:value-display="slotProps">
                                        <p v-if="slotProps.value" class="subtitle-1 font-weight-medium black--text">
                                            {{ slotProps.value.street }} {{ slotProps.value.number }} {{ slotProps.value.bus
                                            }}
                                            <br />
                                            {{ slotProps.value.postalCode }} {{ slotProps.value.city }} {{
                                                slotProps.value.region }}
                                            <br />
                                            <country-flag v-if="slotProps.value.countryCode"
                                                :country="slotProps.value.countryCode" size="small" rounded /> {{
                                                    translateNationalitiy(slotProps.value.countryCode) }}
                                        </p>
                                    </template>
                                </BasicInformationItem>
                            </v-col>
                        </v-row>
                    </v-col>
                    <GoogleMapsFrame :mapsURL="getGoogleMapsUrl(clientInformation)" 
                    unavailableMessage="Er is geen adres beschikbaar" :staySmallAboveMedium=true />
                </v-row>
            </v-container>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue';
import BasicInformationItem from '../shared/BasicInformationItem.vue';
import routeNames from "@/router/RouteNames";
import { translateNationalitiy } from "@/shared/utils/translateUtils";
import CountryFlag from 'vue-country-flag'
import { applyDateFormat } from "@/shared/utils/dateUtils"
import { getGoogleMapsUrl } from "@/shared/utils/requestUtils"
import GoogleMapsFrame from "@/components/shared/GoogleMapsFrame.vue"


export default {
    name: "clientInformationComponent",
    props: {
        clientId: {
            type: String,
            required: true
        },
    },
    components: {
        BasicInformationCard,
        BasicInformationItem,
        CountryFlag,
        GoogleMapsFrame,
    },
    data() {
        return {
            routeNames,
            clientInformation: {
                startDate: null,
                endDate: null,
                vat: null,
            },
            promises: [
                this.$store.dispatch("clientsModule/fetchClientInformation", { clientId: this.clientId })
                    .then(result => {
                        this.clientInformation = result
                    })
            ]
        }
    },
    methods: {
        translateNationalitiy,
        applyDateFormat,
        getGoogleMapsUrl,
    },
}
</script>