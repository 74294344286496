<template>
  <div>
    <ClientDetailHeaderComponent :clientId="clientId"/>
    <v-row>
      <v-col cols="12" lg="9" class="d-flex">
        <ClientInformationComponent :client-id="clientId" class="flex-grow-1"/>
      </v-col>
      <v-col cols="12" lg="3" class="d-flex">
        <ClientContactInformationComponent :client-id="clientId"  class="flex-grow-1"/>
      </v-col>
      <v-col cols="12" class="d-flex">
        <ClientDetailProjectListComponent :clientId="clientId" class="flex-grow-1"></ClientDetailProjectListComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientDetailHeaderComponent from "@/components/clientDetail/ClientDetailHeaderComponent";
import ClientDetailProjectListComponent from "@/views/client/ClientDetailProjectListComponent";
import ClientInformationComponent from "../../components/clientDetail/ClientInformationComponent.vue";
import routeNames from "@/router/RouteNames";
import ClientContactInformationComponent from '../../components/clientDetail/ClientContactInformationComponent.vue';

export default {
  name: "ClientDetail.vue",
  components: {
    ClientDetailProjectListComponent,
    ClientDetailHeaderComponent,
    ClientInformationComponent,
    ClientContactInformationComponent,
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
        return {
            routeNames,
        }
    },
}
</script>