<template>
  <PromiseContainer :promises.sync="computedPromises">
    <div>
      <v-row>
        <v-col v-if="avatarEditRoute" class="flex-grow-0">
          <router-link v-if="avatarEditRoute" :to="avatarEditRoute" class="clickable">
            <v-img outlined :width="150" :height="150" class="background--white border rounded text-right"
                   aspect-ratio="1" :src="getAvatar">
              <v-icon dense class="vertical-align-baseline ma-1">mdi-pencil</v-icon>
            </v-img>
          </router-link>
        </v-col>
        <v-col v-else class="flex-grow-0">
          <v-img outlined :width="150" :height="150" class="background--white border rounded"
                 aspect-ratio="1" :src="getAvatar"/>
        </v-col>
        <v-col class="flex-grow-1">
          <v-row dense>
            <v-col cols="12">
              <h1>
                <Editable :route="titleEditRoute">{{ title }}</Editable>
              </h1>
            </v-col>
            <slot name="content"></slot>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="!disableActionButtons">
        <v-col>
          <router-link v-if="returnLinkRoute" :to="returnLinkRoute"
                       class="secondary text-decoration-none clickable">
            <v-icon class="vertical-align-bottom">mdi-chevron-left</v-icon>
            {{ returnLinkText }}
          </router-link>
        </v-col>
        <v-col cols="auto" class="flex-grow-0">
          <v-chip :color="active ? 'green' : 'red'" label large class="h-100">
            {{ active ? activeText : inactiveText }}
          </v-chip>
        </v-col>
        <v-col cols="auto" class="flex-grow-0">
          <v-menu offset-y left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined class="text-none" color="secondary" v-bind="attrs" v-on="on">
                {{ menuButtonText }}
                <v-icon left class="ma-0">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <slot name="actions"></slot>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
  </PromiseContainer>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue"
import Editable from "./Editable.vue"

export default {
  name: "BasicDetailHeaderComponent",
  components: {
    PromiseContainer,
    Editable,
  },
  props: {
    promises: Array,
    placeHolderImage: String,
    title: String,
    titleEditRoute: Object,
    returnLinkRoute: Object,
    returnLinkText: String,
    avatarEditRoute: Object,
    active: {
      default: false,
    },
    activeText: String,
    inactiveText: String,
    menuButtonText: String,
    disableActionButtons: {
      type: Boolean,
      default: false,
    },
    retrieveAvatar:String,
    defaultAvatar: {
      type: String,
      required: true
    },
    id:{
      type:String,
      required: false,
    },
  },
  data() {
    return {
      SASKey: null,
    }
  },
  emits: ["update:promises"],
  created() {
    this.getSASKey()
  },
  computed: {
    computedPromises: {
      get() {
        return this.promises
      },
      set(newValue) {
        this.$emit("update:promises", newValue)
      },
    },
    getAvatar() {
      if (this.retrieveAvatar) {
        let avatar = this.$store.getters[this.retrieveAvatar](this.id)
        if (avatar && this.SASKey) {
          return avatar + '&' + this.SASKey
        }
      }
      return require('@/assets/' + this.defaultAvatar)
    },
  },
  methods: {
    getSASKey(){
      this.$store.dispatch("fileExportModule/fetchSASKey")
          .then(result => this.SASKey = result)
    }
  }
}
</script>
