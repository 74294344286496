<template>
  <v-toolbar dark dense flat>
    {{ title }}
    <v-spacer></v-spacer>
    <slot name="actions"></slot>
    <v-btn v-if="!withoutClose" icon dark @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "BasicToolbar",
  props: {
    title: String,
    withoutClose: {
      default: false,
      type: Boolean
    },
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>